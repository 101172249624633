import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router/index'

// Notification

export default function useRosterGroupList() {
  // Use toast
  const toast = useToast()
  const route = router.history.current

  const refRosterGroupsTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'name',
      label: 'group name',
      sortable: true,
    },
    {
      key: 'abbreviation',
      label: 'ABBRV.',
      sortable: true,
    },
    {
      key: 'dayOffDays',
      label: 'Day-off',
      sortable: false,
    },
    {
      key: 'remarks',
      label: 'Remarks',
      sortable: false,
    },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(route.query.perPage ?? process.env.VUE_APP_PER_PAGE_CONTENT)
  const totalRosterGroups = ref(0)
  const searchQuery = ref(route.query.search || '')
  const currentPage = ref(route.query.page ?? 1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref(route.query.sortBy ?? 'name')
  const isSortDirDesc = ref(route.query.sortDesc === 'true')
  const statusFilter = ref(route.query.status ?? null)
  const toDeletedID = ref(null)
  let initialLoad = true
  const currentDataLength = ref(0)

  const dataMeta = computed(() => {
    const localItemsCount = refRosterGroupsTable.value ? refRosterGroupsTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRosterGroups.value,
    }
  })

  const refetchData = () => {
    refRosterGroupsTable.value.refresh()
  }

  watch([searchQuery, statusFilter], () => {
    currentPage.value = 1
    refetchData()
  }, { deep: true })

  watch([currentPage], () => {
    refetchData()
  }, { deep: true })

  const fetchRosterGroups = (ctx, callback) => {
    store
      .dispatch('app-roster-groups/fetchRosterGroups', {
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      },
      { root: true })
      .then(response => {
        const { rosterGroups, total } = response.data
        currentDataLength.value = rosterGroups.length
        callback(rosterGroups)
        totalRosterGroups.value = total
        if (!initialLoad) {
          router.replace({
            query: {
              search: searchQuery.value,
              perPage: perPage.value,
              page: currentPage.value,
              sortBy: sortBy.value,
              sortDesc: isSortDirDesc.value,
              status: statusFilter.value,
            },
          }).catch(() => {})
        }
        initialLoad = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteRosterGroup = () => {
    store
      .dispatch('app-roster-groups/deleteRosterGroup', {
        id: toDeletedID.value,
      },
      { root: true })
      .then(response => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message ?? '',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveRosterGroupStatusVariant = status => {
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'danger'
    return 'primary'
  }

  return {
    fetchRosterGroups,
    tableColumns,
    perPage,
    currentPage,
    totalRosterGroups,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refRosterGroupsTable,

    searchQuery,

    resolveRosterGroupStatusVariant,
    refetchData,
    currentDataLength,

    toDeletedID,
    deleteRosterGroup,
    statusFilter,
  }
}
